<template>
<div class="settingprofile">
  <div id="section-content" class="container container-fluid">
    <div class="bar-back">
      <router-link to="/member/main"><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link>
    </div>
    <div
        class="p-2 m-0 w-100 bg-light  main-content"
        style="min-height: calc((100vh - 140px) - 50px)">
        <app-menu-profile/>
      <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0">
        <div class="lotto-title">
          <h4>เพชร</h4>
        </div>
      </div>
      <div class="bgwhitealpha text-secondary rounded p-2 mb-5 xtarget col-lotto">
        <div class="d-block text-center" id="section_vip"  ref="section_vip">
        <div class="card-body p-0">
          <div class="row justify-content-center mb-4 text-shadow">
            <div class="col-sm-12">
                <div class="card-body p-0">
                  <p class="card-text text-center m-0">
                    <strong>เพชรของคุณ</strong>
                  </p>
                  <h5 class="card-title text-center">0 <img src="@/assets/icon/slot-machine.png" class="-icon icon_mb_menu" alt="Menu icon promotion"></h5>
                </div>
            </div>
            <div class="col-sm-12 p-3">
              <p class="m-0 text-left">
                รายการการแลกของรางวัล
              </p>
              <!-- item list -->
              <div class="row shadow-sm p-2 box_reward"  v-for="(item, index) in promotions_gem" :key="index">
                <div class="col-4 col-sm-4">
                  <img :src="item.img" class="w-100 bg-radius  img_reward">
                </div>
                <div class="col-5 col-sm-5 text-left">
                  <p class="m-0">{{ item.point }} <img  src="@/assets/icon/slot-machine.png" class="-icon icon_mb_menu"></p>
                  <p class="m-0">แลก</p>
                  <p class="m-0"> <span class="btn_new_user">{{ item.reward }}</span></p>
                </div>
                <div class="col-3 col-sm-3">
                  <button class="get_reward" @click="getReward(index)" v-if="false">รับ</button>
                  <button class="get_reward get_reward_dis" v-if="true" disabled>N/A</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import menu_profile from '@/components/menu_profile'
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    balance: {
      type: String,
      default: '0.00'
    },
    username: {
      type: String,
      default: ''
    },
    credit_total: {
      default: 0
    },
    vip_level: {
      default: 0
    }
  },
  components: {
    'app-menu-profile': menu_profile
  },
  mounted () {
    // location.href = '#section_vip'
    const labelInputRef = this.$refs.section_vip
    labelInputRef.focus()
  },
  data () {
    return {
      promotions_gem: [
        { point: 500, reward: '250 บาท', img: require('@/assets/temp/money.webp') },
        { point: 5000, reward: 'ทองครึ่งสลึง', img: require('@/assets/temp/gold.jpg') },
        { point: 12000, reward: 'Ipad9', img: require('@/assets/temp/ipad9.jpg') }
      ]
    }
  },
  methods: {
    ...mapActions(['getBalanceInfo', 'setLoading']),
    numberWithCommas (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    getReward (index) {
      this.$swal({
        text: 'คุณต้องการใช้' + this.promotions_gem[index].point + 'เพชร แลก ' + this.promotions_gem[index].reward + ' ใข่ไหม ?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ใช่',
        cancelButtonText: 'ปิด',
        imageUrl: this.promotions_gem[index].img,
        imageWidth: '100%'
      }).then((result) => {
      })
    }
  },
  computed: {
    ...mapGetters(['getIsVip'])
  }
}
</script>

<style>
</style>
